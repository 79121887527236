<style lang="less" scoped>
.extra-wrapper {
  line-height: 55px;
  padding-right: 24px;

  .extra-item {
    display: inline-block;
    margin-right: 24px;

    a {
      margin-left: 24px;
    }
  }
}
html,
body {
  height: 100%;
  overflow-y: scroll;
}
.antd-pro-pages-dashboard-analysis-twoColLayout {
  position: relative;
  display: flex;
  display: block;
  flex-flow: row wrap;
  padding-bottom: 50px;
  overflow: hidden;
}

.antd-pro-pages-dashboard-analysis-salesCard {
  height: calc(100% - 24px);
  /deep/ .ant-card-head {
    position: relative;
  }
}

.dashboard-analysis-iconGroup {
  i {
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transition: color 0.32s;
    color: black;
  }
}
.analysis-salesTypeRadio {
  position: absolute;
  right: 54px;
  bottom: 12px;
}
.ant-select-selection__rendered {
  line-height: 35px !important;
}
.table-operator {
  // margin-top: 20px;
}
</style>

<style  lang="less" scoped>
/deep/ .ant-card-body {
  padding-bottom: 14px !important;
  padding-right: 10px !important;
}
.ant-input-affix-wrapper,
.ant-select-selection--single {
  height: 35px !important;
}
.ant-select-selection__rendered {
  line-height: 35px !important;
}
.input-left {
  width: 100px !important;
}
.ant-card-head {
  background-color: #2c3e5023;
}
/deep/ .ant-form-item-with-help {
  margin-bottom: 0 !important;
  line-height: 25px;
}
.ant-input {
  height: 35px;
}
/deep/ .ant-upload-select-picture-card {
  min-width: 80px !important;
  min-height: 80px !important;
}
.recipient-multi-box {
  box-sizing: border-box;
  width: 100%;
  margin-left: 0 !important;
  > :nth-child(1) {
    padding-left: 0 !important;
  }
  > :nth-child(2) {
    padding-right: 0 !important;
  }
}
.Send-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  box-sizing: border-box;
  .ant-row-flex {
    margin-left: 0 !important;
    width: 49%;
    margin-right: 20px !important;
    // border: 1px solid #2c3e5024;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    /*考虑浏览器兼容性*/
    -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    > :nth-child(1) {
      padding-left: 0 !important;
    }
    > :nth-child(2) {
      padding-right: 0 !important;
    }
  }
  > :nth-child(odd) {
    margin-right: 0 !important;
  }
  > :nth-child(even) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>
<template>
  <div class="page-header-index-wide" v-if="isRouterAlive">
    <div class="antd-pro-pages-dashboard-analysis-twoColLayout" :class="isDesktop() ? 'desktop' : ''">
      <div class="table-operator">
        <a-button type="primary" @click="validate()" v-preventReClick>下单并接单</a-button>
      </div>

      <div class="table-operator" style="margin-top: 20px">
        <a-button type="primary" @click="addAddress()">新增收货地址</a-button>
        &nbsp;&nbsp;&nbsp;
        <a-radio-group v-model="disPick">
          <a-radio :key="index" v-for="(item, index) in disPickData" :value="index">{{ item }}</a-radio>
        </a-radio-group>
      </div>

      <a-row :gutter="24" type="flex" :style="{ marginTop: '24px' }">
        <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card class="card" title="寄方" :bordered="false">
            <send-form ref="send" :showSubmit="false"  :SendTheIndex="'3'" />
          </a-card>
        </a-col>
      </a-row>

      <a-row :gutter="24" type="flex" :style="{ marginTop: '24px' }" class="recipient-multi-box">
        <a-col :xl="9" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :bordered="false" title="物品信息" :style="{ height: '100%' }">
            <goods-form ref="goods" :showSubmit="false" />
          </a-card>
        </a-col>

        <a-col :xl="15" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :bordered="false" title="订单信息" :style="{ height: '100%' }">
            <order-form ref="order" :showSubmit="false" />
          </a-card>
        </a-col>
      </a-row>

      <!--
          <a-row :gutter="24" type="flex" :style="{ marginTop: '24px' }">

            <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
              <a-card  :bordered="false" title="收方" :style="{ height: '100%' }">
                   <recipient-form ref="recipient_0" :showSubmit="false"/>
              </a-card>
            </a-col>

            <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
              <a-card  :bordered="false" title="商品信息" :style="{ height: '100%' }">
                    <recipient-goods-form ref="recipientGoods_0" :showSubmit="false"/>
              </a-card>
            </a-col>

         </a-row>
         -->
      <div class="Send-box">
        <a-row :gutter="24" type="flex" :style="{ marginTop: '24px' }" v-for="idx in total" :key="idx">
          <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
            <a-card :bordered="false" title="收方" :style="{ height: '100%' }">
              <recipient-form :ref="'recipient_' + idx" :showSubmit="false" :debit="'3'" />
            </a-card>
          </a-col>

          <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
            <a-card :bordered="false" title="商品信息" :style="{ height: '100%' }">
              <recipient-goods-form :ref="'recipientGoods_' + idx" :showSubmit="false" />
            </a-card>
          </a-col>
        </a-row>
      </div>
    </div>

    <a-modal v-model="orderConfirmVisible" title="下单成功">
        <p>下单成功，请提醒调度及时安排提货</p>
        <template slot="footer">
          <a-button key="forward1"  @click="orderFreshen">刷新页面</a-button>
          <a-button key="forward"  type="primary" @click="orderConfirm">确认</a-button>
        </template>
    </a-modal>

  </div>
</template>

<script>
import moment from 'moment'
import { mixinDevice } from '@/utils/mixin'
import SendForm from './OnlineDisperseOrder/SendForm'
import RecipientForm from './OnlineDisperseOrder/RecipientForm'
import GoodsForm from './WholeCarOrder/GoodsForm'
import OrderForm from './WholeCarOrder/DeliveryOrderForm'
import RecipientGoodsForm from './WholeCarOrder/RecipientGoodsForm'
import { enumerationData  } from '@/api/common'

import {
  onlineDisperseOrder,
  saveMultiOrder,
  orderTransportPrice,
  requestReceiverAddressParam,
  requestShipperAddressParam,
} from '@/api/order'

export default {
  name: 'WholeCarOrder',
  mixins: [mixinDevice],
  components: {
    SendForm,
    GoodsForm,
    OrderForm,
    RecipientForm,
    RecipientGoodsForm,
    onlineDisperseOrder,
  },

  data() {
    return {
      total: 2,
      isRouterAlive: true,
      disPick: '1',
      orderConfirmVisible:false,
      disPickData: { 1: '请按顺序提货', 2: '司机自主提货' },
    }
  },
  mounted() {
    this.loadData()    
  },
  methods: {
    loadData(){
        this.$refs.send.send()  
        this.$refs.goods.goods()
        this.$refs.order.order()  
        this.total = 2; 
        for (let i = 1; i <= this.total; i++) {          
          let recipient = 'recipient_' + i
          let recipientGoods = 'recipientGoods_' + i
          this.$refs[recipient][0].recipient()
          this.$refs[recipientGoods][0].recipientGoods()
        }          
        this.loadEnumerationData()
    },  
    loadEnumerationData(){
        var enumerationParam = {tableName:'sysCOrder'}
        enumerationData(enumerationParam)
         .then(res => {
            if(res.code == 0){

              this.$refs.order.orderLoad(res.result)

            }else{
              this.$notification.error({
                message: '订单加载失败',
                description: res.message
              })
            }
          })


        var enumerationParam = {tableName:'sysCOrderGoods'}
        enumerationData(enumerationParam)
         .then(res => {
            if(res.code == 0){

              this.$refs.order.goodsLoad(res.result)

            }else{
              this.$notification.error({
                message: '订单加载失败',
                description: res.message
              })
            }
          })

    },

    validate() {
      const {
        $refs: { send, goods, order },
        $notification,
      } = this
      const sendForm = new Promise((resolve, reject) => {
        send.form.validateFields((err, values) => {
          console.log(values)
          if (err) {
            reject(err)
            return
          }
          var valuesData = requestShipperAddressParam(values)
          resolve(valuesData)
        })
      })

      const goodsForm = new Promise((resolve, reject) => {
        goods.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          resolve(values)
        })
      })

      const orderForm = new Promise((resolve, reject) => {
        order.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          values.expectPickupDate = moment(values.expectPickupDate).format('YYYY-MM-DD HH:mm:ss')
          values.expectDispatchDate = ''
          resolve(values)
        })
      })

      var paramForm = [sendForm, goodsForm, orderForm]
      for (let i = 1; i <= this.total; i++) {
        let recipientForm = 'recipientForm_' + i
        let recipientGoodsForm = 'recipientGoodsForm_' + i
        let recipient = 'recipient_' + i
        let recipientGoods = 'recipientGoods_' + i

        recipientForm = new Promise((resolve, reject) => {
          this.$refs[recipient][0].form.validateFields((err, values) => {
            if (err) {
              reject(err)
              return
            }
            var valuesData = requestReceiverAddressParam(values)
            console.log('valuesData' + i, valuesData)
            resolve(valuesData)
          })
        })

        recipientGoodsForm = new Promise((resolve, reject) => {
          this.$refs[recipientGoods][0].form.validateFields((err, values) => {
            if (err) {
              reject(err)
              return
            }
            ;(values.expectPickupDate = ''),
              (values.expectDispatchDate = moment(values.expectDispatchDate).format('YYYY-MM-DD HH:mm:ss'))
            values.weight = 0
            values.volume = 0
            console.log('values' + i, values)
            resolve(values)
          })
        })
        paramForm.push(recipientForm)
        paramForm.push(recipientGoodsForm)
      }

      Promise.all(paramForm).then((list_values) => {
        console.log('list_values', list_values)

        var sendInfo = list_values[0]
        var goodsInfo = list_values[1]
        sendInfo.goodsInfo = goodsInfo
        var shipperList = [sendInfo]
        var key = 3
        var recipientInfoList = []
        var goodsInfoList = []
        var receiverList = []
        for (let i = 1; i <= this.total; i++) {
          var recipientInfo = {}
          var goodsInfo = {}
          recipientInfo = list_values[key]
          goodsInfo = list_values[key + 1]
          recipientInfo.goodsInfo = goodsInfo
          receiverList.push(recipientInfo)
          key = key + 2
        }

        var dispatchSeq = 2
        if (this.disPick == 2) {
          dispatchSeq = 1
        }
        var orderParam = { pickupWay: 1, pickupSeq: 1, dispatchWay: 2, dispatchSeq: dispatchSeq }
        orderParam = Object.assign({}, orderParam, list_values[2])
        orderParam.shipperList = shipperList
        orderParam.receiverList = receiverList

        return saveMultiOrder(orderParam).then((res) => {
          if (res.code == 0) {
            const _this = this            
            this.orderConfirmVisible = true             
          } else {
            this.$notification['error']({
              message: '下单失败',
              description: res.message,
            })
          }
          return res.result
        })
      })
    },

    addAddress() {
      this.total++
    },

    orderFreshen(){
        this.orderConfirmVisible = false
        this.loadData()        
    },
    orderConfirm(){
        this.orderConfirmVisible = false
    },

  },

  created() {},
}
</script>

