<template>
  <a-form layout="inline"  :form="form" class="form">
    <a-form-item label="温区">
      <a-select v-decorator="['temperatureZone', { rules: [{ required: true, message: '温区' }] }]" placeholder="温区">
        <a-select-option :key="index" v-for="(item, index) in temperatureZoneData" :value="item.enumValue">
          {{ item.enumName }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="声明价值">
      <a-input-number :min="1"
        :max="500000"
        :step="1"
        :precision="0" v-decorator="['insuredPrice']" />
    </a-form-item>
    <a-form-item layout="inline" label="回单类型">
      <a-select
        v-decorator="['receiptType', { rules: [{ required: true, message: '回单类型' }] }]"
        placeholder="回单类型"
      >
        <a-select-option :key="index" v-for="(item, index) in receiptTypeData" :value="item.enumValue">
          {{ item.enumName }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <br />
    <a-form-item layout="inline" label="是否进口">
      <a-radio-group
        @change="selectImport($event)"
        v-decorator="['isImport', { initialValue: 0, rules: [{ required: true }] }]"
      >
        <a-radio :key="index" v-for="(item, index) in isImportData" :value="item.enumValue" >{{
          item.enumName
        }}</a-radio>
      </a-radio-group>
    </a-form-item>

    <div layout="inline" label="" v-if="uploadShow" style="width: 100%">
      <a-list :grid="{ gutter: 20, column: 3 }">
        <a-list-item>
          <a-upload
            name="depositReceipt"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :customRequest="uploadImg"
            accept=".jpg,.png,.jpeg"
          >
            <a-form-item layout="inline" :class="[uploadShow ? 'ant-form-item1' : '']">
              <a-input v-show="false" v-decorator="['depositReceipt', { initialValue: '',rules: [{ required: true,message:'关单' }] }]" />
            </a-form-item>
            <img id="depositReceiptUrl" v-if="depositReceiptUrl" :src="depositReceiptUrl" alt="avatar" />
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">关单</div>
            </div>
          </a-upload>
        </a-list-item>

        <a-list-item>
          <a-upload
            name="pratique"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :customRequest="uploadImg"
            accept=".jpg,.png,.jpeg"
          >
            <a-form-item layout="inline" :class="[uploadShow ? 'ant-form-item1' : '']">
              <a-input v-show="false" v-decorator="['pratique', { initialValue: '',rules: [{ required: true,message:'检验检疫证' }] }]" />
            </a-form-item>
            <img id="pratiqueUrl" v-if="pratiqueUrl" :src="pratiqueUrl" alt="avatar" />
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">检验检疫证</div>
            </div>
          </a-upload>
        </a-list-item>

        <a-list-item>
          <a-upload
            name="nuclein"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :customRequest="uploadImg"
            accept=".jpg,.png,.jpeg"
          >
            <a-form-item layout="inline" :class="[uploadShow ? 'ant-form-item1' : '']">
              <a-input v-show="false" v-decorator="['nuclein', { initialValue: '',rules: [{ required: true,message:'核酸检测报告' }] }]" />
            </a-form-item>
            <img v-if="nucleinUrl" id="nucleinUrl" :src="nucleinUrl" alt="avatar" />
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">核酸检测报告</div>
            </div>
          </a-upload>
        </a-list-item>
      </a-list>
    </div>
    <br />

    <a-form-item label="计费方式">
      <a-select
        v-decorator="['chargeType', { rules: [{ required: true, message: '计费方式' }] }]"
        placeholder="计费方式"
      >
        <a-select-option
          :key="item.enumValue"
          v-if="item.enumValue != 0"
          v-for="item in chargeTypeData"
          :value="item.enumValue"
        >
          {{ item.enumName }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <br />

    <a-form-item layout="inline" label="发货时间">
      <a-date-picker
        v-decorator="['expectPickupDate', { rules: [{ required: true, message: '发货时间' }] }]"
        name="expectPickupDate"
        show-time
        style="width: 100%"
      />
    </a-form-item>

    <a-form-item layout="inline" label="车辆类型">
      <a-select
        v-decorator="['vehicleType', { rules: [{ required: true, message: '车辆类型' }] }]"
        placeholder="车辆类型"
      >
        <a-select-option :key="index" v-for="(item, index) in vehicleTypeData" :value="item.enumValue">
          {{ item.enumName }}
        </a-select-option>
      </a-select>
    </a-form-item>
  </a-form>
</template>

<script>
import pick from 'lodash.pick'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { put } from '@/utils/upload'
import Vue from 'vue'

export default {
  name: 'OrderForm',
  components: {},
  props: {
   
  },
  mounted() {
    this.order()
  },
  data() {
    return {
      temperatureZoneData: {},
      receiptTypeData: {},
      paymentWayData: {},
      isImportData: {},
      pickupTypeData: {},
      dispatchTypeData: {},
      vehicleTypeData: {},
      chargeTypeData: {},
      imgLoading: false,
      imageUrl: '',
      uploadShow: false,
      companyCustomerCodeShow: false,
      nucleinUrl: '',
      pratiqueUrl: '',
      depositReceiptUrl: '',
      form: this.$form.createForm(this),
    }
  },
 
  methods: {
    order(){         
        const {
          form: { setFieldsValue },
        } = this
        this.form.resetFields()
    },
    orderLoad(orderEnumerationData) {
      this.receiptTypeData = orderEnumerationData.receiptType
      this.paymentWayData = orderEnumerationData.paymentWay
      this.pickupTypeData = orderEnumerationData.pickupType
      this.dispatchTypeData = orderEnumerationData.dispatchType
      this.chargeTypeData = orderEnumerationData.chargeType
      console.log('chargeTypeData', this.chargeTypeData)
    },
    goodsLoad(goodsEnumerationData) {
      this.temperatureZoneData = goodsEnumerationData.temperatureZone
      this.isImportData = goodsEnumerationData.isImport
      this.vehicleTypeData = goodsEnumerationData.vehicleType
      console.log('temperatureZoneData', this.temperatureZoneData)
    },

   
    uploadImg(action) {
      let that = this
      this.imgLoading = true
      const file = action.file
      var mark = action.filename
      console.log('action', action)
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          let fileName = result.name
          if (mark == 'nuclein') {
            this.form.setFieldsValue({
              nuclein: fileName,
            })
          } else if (mark == 'pratique') {
            this.form.setFieldsValue({
              pratique: fileName,
            })
          } else if (mark == 'depositReceipt') {
            this.form.setFieldsValue({
              depositReceipt: fileName,
            })
          }
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, mark)
        })
        .catch((e) => {
          console.info(e)
        })
    },

    async getimgblob(url, id) {
      let token = Vue.ls.get(ACCESS_TOKEN)
      let request = new XMLHttpRequest()
      request.timeout = 9000
      request.responseType = 'blob'
      request.open('get', url, true)
      // 携带请求头
      request.setRequestHeader('token', token)
      request.onreadystatechange = (e) => {
        if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {          
          var imageUrl = URL.createObjectURL(request.response)         
          if (id == 'nuclein') {
            this.nucleinUrl = imageUrl
          } else if (id == 'pratique') {
            this.pratiqueUrl = imageUrl
          } else if (id == 'depositReceipt') {
            this.depositReceiptUrl = imageUrl
          }
        } else {
          return ''
        }
      }
      request.send(null)
    },
    selectImport($event) {
      var isImport = $event.target.value
      if (isImport == 1) {
        this.uploadShow = true
      } else {
        this.uploadShow = false
      }
    },
    paymentWayChange($paymentWay) {
      if ($paymentWay == '4') {
        this.companyCustomerCodeShow = true
      } else {
        this.companyCustomerCodeShow = false
      }
    },
  },
}
</script>
<style lang="less" scoped>
.form {
  display: flex;
  flex-wrap: wrap;
  /deep/ .ant-form-item {
    min-height: 60px;
    width: 50%;
    margin-right: 0 !important;
  }
  /deep/ .ant-form-item-label {
    width: 100px !important;
    text-align: left !important;
  }
  /deep/ .ant-form-item-control-wrapper {
    width: 45%;
  }
  /deep/ .ant-input {
    width: 100%;
  }
  // }
}
/deep/ #depositReceiptUrl,
#pratiqueUrl,
#nucleinUrl {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
/deep/ .avatar-uploader > .ant-upload {
  width: 110px;
  height: 110px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
/deep/ .ant-upload{
  padding: 0 !important;
}
/deep/ .ant-radio-wrapper {
      margin-right: 0 !important;
  > span{
    padding-left: 3px !important;
  }
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.ant-form-item1 {
  min-height: 0 !important;
  width: auto !important;
  margin-right: 0 !important;
}
/deep/ .ant-spin-container {
  > div {
    padding: 0 !important;
    width: auto;
    margin-right: 20px;
  }
}
.ant-radio-wrapper-checked{
  margin-right: 0 !important;
}
/deep/ .ant-input-number{
  width: 100%;
}
</style>