<style lang="less" scoped>
.table-operator {
  margin-top: 20px;
}
/deep/ .ant-form-item-label {
  width: 100px;
  text-align: left !important;
}
/deep/ .ant-form-item-control-wrapper {
  width: 55%;
}
/deep/ .ant-form-item {
  min-height: 60px;
  width: 100%;
  /deep/ .ant-input {
    width: 100%;
  }
}
.goods-input{
 /deep/ .ant-form-item-children{
    display: flex;
    .ant-input-number{
      border-radius: 5px 0 0 5px ;
    }
    .ant-btn{
      border-radius:0 5px 5px 0;
      border-left: none;
      width: 20% !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
/deep/ .ant-input-number{
  width: 100%;
}
</style>
<template>
  <a-form layout="inline" style="width: 100%" @submit="handleSubmit" :form="form" class="form">
    <a-form-item label="物品名称">
      <a-input v-decorator="['goodsName', { rules: [{ required: true, message: '物品名称' }] }]" placeholder="10字以内" :maxLength="10"/>
    </a-form-item>
    <br />
    <a-form-item label="总件数">
      <a-input-number  :min="1"
        :max="99999"
        :step="1"
        :precision="0" v-decorator="['goodsNum', { rules: [{ required: true, message: '总件数' }] }]" />
    </a-form-item>
    <br />
    <a-form-item label="总重量"  class="goods-input">
      <a-input-number :min="0.1"
        :max="99999"
        :step="0.1"
        :precision="1" v-decorator="['weight', { rules: [{ required: true, message: '重量' }] }]" style="width: 90%" /> <a-button disabled>KG</a-button>
    </a-form-item>
    <br />
    <a-form-item label="总体积"  class="goods-input">
      <a-input-number  :min="0.01"
        :max="99999"
        :step="0.01"
        :precision="2" v-decorator="['volume', { rules: [{ required: true, message: '体积' }] }]" style="width: 90%" />  <a-button disabled>M³</a-button>
    </a-form-item>
    <br />
  </a-form>
</template>    
<script>
function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
export default {
  name: 'GoodsForm',
  components: {},
  props: {
   
  },
  data() {
    return {
      totalVolume: '',
      imageUrl: '',     
      form: this.$form.createForm(this),
    }
  },
  mounted() {
   this.goods()
  },
  methods: {
    goods(){
      const {
        form: { setFieldsValue },
      } = this
      this.form.resetFields()
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$notification['error']({
            message: 'Received values of form:',
            description: values,
          })
        }
      })
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.imgLoading = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl
          this.imgLoading = false
        })
      }
    },
  },
}
</script>
