<style lang="less" scoped>
.table-operator {
  margin-top: 20px;
}
.ant-form-item {
  width: 100%;
  min-height: 60px;
  // display: flex;
}
/deep/ .ant-form-item-label {
  width: 75px !important;
  text-align: left !important;
}
/deep/ .ant-form-item-control-wrapper {
  width: 75%;
}
</style>
<template>
  <a-form layout="inline"  :form="form" class="form">
    <a-form-item label="物品名称">
      <a-input v-decorator="['goodsName', { rules: [{ required: true, message: '物品名称' }] }]" :maxLength="10" placeholder="10字以内" style="width: 100%" />
    </a-form-item>
    <a-form-item label="到货时间">
      <a-date-picker
        v-decorator="['expectDispatchDate', { rules: [{ required: true, message: '发货时间' }] }]"
        show-time
        style="width: 100%"
      />
    </a-form-item>
    <br />
    <a-form-item label="总件数">
      <a-input-number :min="1"
        :max="99999"
        :step="1"
        :precision="0" v-decorator="['goodsNum', { rules: [{ required: true, message: '总件数' }] }]" style="width: 100%" />
    </a-form-item>
    <br />
    <a-form-item label="备注">
      <a-textarea placeholder="150字以内"
        auto-size
        v-decorator="['goodsRemark', { rules: [{ required: true, message: '总件数' }] }]"
        style="width: 100%"
      :maxLength="150"/>
    </a-form-item>
  </a-form>
</template>    
<script>
export default {
  name: 'RecipientGoodsForm',
  components: {},
  props: {
    showSubmit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {             
      form: this.$form.createForm(this),
    }
  },
  mounted() {   
   this.recipientGoods();
  },
  methods: {
    recipientGoods(){
      const {
        form: { setFieldsValue },
      } = this
      this.form.resetFields()
    }
  },
}
</script>
